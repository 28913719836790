.accordionWrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-family: 'DM Sans';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    .headerTab {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      flex-grow: 1;
      align-self: stretch;
      p {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        margin: 0;
      }
      .headerAction {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .actionWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          &.disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        }
      }
    }

    &.command {
      color: #232a34;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  .accordion {
    .accordionTab {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .accordionTabRoot_command {
      margin-bottom: 0;
      &:not(:last-child) {
        border-bottom: 1px solid #ccd8ea;
      }
    }
    :global {
      .accordionTab-command {
        &.p-accordion-tab-active {
          .p-accordion-header-link {
            background: #fff !important;
          }
          .p-accordion-content {
            background: #fff !important;
            &:empty {
              transition: none;
              display: none;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
    .tab {
      .emptyTabHeader,
      .hideCollapse {
        :global {
          .expandIcon,
          .collapseIcon {
            display: none;
          }
        }
      }
      &.command {
        margin-bottom: 0;
        &:last-child {
          .p-accordion-header-link {
            border-bottom: 1px solid #ccd8ea;
            &:hover {
              border-bottom: 1px solid #ccd8ea;
            }
          }
        }
        :global {
          .p-accordion-header-link {
            border-radius: 0.25rem 0.25rem 0rem 0rem;
            background: #f5f7fa;
          }
          .p-accordion-header-text {
            color: #232a34;
            font-family: 'DM Sans';
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
          }
          .p-accordion-content {
            background: #f5f7fa;
          }
        }
      }
      &.summary {
        :global {
          .p-accordion-header-link {
            border-radius: 0.25rem 0.25rem 0rem 0rem;
            background: rgba(45, 156, 219, 0.2);
          }
          .p-accordion-header-text {
            color: #2d9cdb;
          }
          .p-accordion-content {
            background: rgba(45, 156, 219, 0.2);
          }
        }
      }
      .tabContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        border-radius: 0.25rem;
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        &.command {
          padding: 0;
        }
        &.summary {
          padding: 1.5rem 1rem;
          background: #f5f7fa;
        }
      }
    }
  }
}
