.tabs {
  display: flex;
  .tabLayout {
    display: flex;
    height: 2.5rem;
    padding: 0.375rem 0.875rem;
    align-items: center;
    cursor: pointer;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    gap: 0.605rem;
    &.tabStyle {
      border-radius: 0.25rem;
      background: #fff;
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem;

      &.active {
        background-color: #f5f7fa;
        transition: background-color 0.3s ease;
      }
      transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
      will-change: transform, box-shadow;
      &:hover {
        box-shadow: 0 0 4px #f5f7fa;
      }
    }
  }
}
