.banner {
  padding: 1rem;
  .bannerLogo {
    margin-bottom: 2.5rem;
  }
  .content {
    display: flex;
    width: 22.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    .body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      .title {
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.875rem;
        margin: 0;
      }
      .text {
        color: #717e90;
        font-family: 'DM Sans';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 0;
      }
    }
    .btnUpgradeSubscription {
      color: #fff;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem;
    }
  }
}
