.detailsPage {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  width: 100%;
  .header {
    display: flex;
    width: 100%;
    padding: 1.25rem 2rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccd8ea;
    background: #fff;
    .reRunCheckIcon {
      &::before {
        color: #fff;
      }
    }
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      .loadingStatus {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        color: #232a34;

        /* Paragraph Text */
        font-family: 'DM Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 171.429% */
        .loading {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .back {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.375rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border-radius: 0.25rem;
        border: 1px solid #ccd8ea;
        background: #f5f7fa;
        cursor: pointer;
        &:hover {
          border: 1px solid #c1cddd;
          background: #ebeff5;
        }
      }
      .title {
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        margin: 0;
        padding: 0;
        line-height: 1.5rem;
        &.ignored {
          text-decoration: line-through;
        }
      }
    }
  }
  .detailsTab {
    > div {
      display: flex;
      min-height: 3.25rem;
      padding: 0rem 1rem;
      align-items: center;
      gap: 1rem;
      align-self: stretch;
      &:nth-child(odd) {
        background-color: #f5f7fa;
      }
      label {
        display: flex;
        color: #232a34;
        min-width: 5.625rem;
        font-family: 'DM Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.125rem;
      }
      p {
        display: flex;
        flex-grow: 1;
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
  }
  .sidebarBodyWrap {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    .sidebarBody {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }
  .contentWrapper {
    position: relative;
    display: flex;
    height: 100%;
    .btnConfigWrapper {
      display: flex;
      margin: 2rem 0 0 1.6rem;
      width: 2.5rem;
      .btnConfig {
        display: flex;
        position: fixed;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.375rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        flex-shrink: 0;
        border-radius: 0.25rem;
        border: 1px solid #ccd8ea;
        background: #eaeff5;
        cursor: pointer;
        &:hover {
          border-radius: 0.25rem;
          border: 1px solid var(--text-links-buttons, #f25844);
          background: rgba(242, 88, 68, 0.1);
          .btnConfigIcon {
            &::before {
              color: #f25844;
            }
          }
        }
      }
    }
    .content {
      display: flex;
      width: 100%;
      background-color: #f5f7fa;
      .body {
        display: flex;
        width: 100%;
        padding: 2rem 2rem 0rem 1.75rem;
      }
      .loading {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        color: #232a34;
        text-align: center;
        font-family: 'DM Sans';
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.375rem;
        align-self: center;
        span {
          color: #232a34;
          text-align: center;
          font-family: 'DM Sans';
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
        }
      }
    }
  }

  .editingFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    flex-grow: 1;
    align-self: stretch;
    .cancel {
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      opacity: 0.7;
      line-height: 1.125rem; /* 128.571% */
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .confirm {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #f25844;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem; /* 128.571% */
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      .icon {
        &::before {
          color: #f25844;
        }
      }
    }
  }
  .outputCommand {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    h5 {
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.375rem;
      margin: 0;
      margin-bottom: 0.75rem;
    }
    .contentOutput {
      position: relative;
      display: flex;
      padding: 1.5rem;
      align-items: flex-start;
      gap: 1rem;
      align-self: stretch;
      border-radius: 0.25rem;
      background: #eaeff5;
      .empty {
        color: #232a34;
        font-size: 0.87rem;
      }
      .jsonViewer {
        color: #232a34;
        font-size: 0.87rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        overflow-wrap: break-word;
      }
      .copy {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        cursor: pointer;
        &:hover {
          &:before {
            color: #f25844;
          }
        }
      }
    }
  }

  .headerSection {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex-grow: 1;
    align-self: stretch;
    .isExecuting {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      .spinner {
        display: flex;
        margin: 0;
        position: relative;
      }
    }
    .title {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      margin: 0;
    }
  }
  .addCommandButtonWrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    .form {
      display: flex;
      padding: 0.75rem 1rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      align-self: stretch;
      border-radius: 0.25rem;
      border: 1px solid #ccd8ea;
      background: #fff;
      .control {
        display: flex;
        align-items: flex-start;
        gap: 0.75rem;
        align-self: stretch;
        .input {
          display: flex;
          background: transparent;
          flex: 1 0 0;
          color: #717e90;
          font-family: 'DM Sans';
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem; /* 150% */
        }
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.5rem;
        align-self: stretch;
      }
    }
    .addCommandButton {
      gap: 0.5rem;
      justify-content: center;
      display: flex;
      align-items: center;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem;
      cursor: pointer;
      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }
      &:hover {
        color: #f25844;
        .icon {
          &::before {
            color: #f25844;
          }
        }
      }
    }
  }
  .commandWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .emptyCommand {
    color: #232a34;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    font-family: 'DM Sans';
    font-style: normal;
    line-height: 1.5rem;
    padding: 0 2.5rem;
  }

  .headerCommand {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex-grow: 1;
    align-self: stretch;
    .editing {
      display: flex;
      flex-grow: 1;
      align-items: flex-start;
      gap: 0.75rem;
      align-self: stretch;
      .input {
        display: flex;
        flex-grow: 1;
        color: #717e90;
        font-family: 'DM Sans';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
      }
    }
    .commandText {
      white-space: break-spaces;
      overflow-wrap: break-word;
      word-break: break-word;
      line-break: auto;
      &.ignored {
        text-decoration: line-through;
      }
    }
    .spinner {
      display: flex;
      margin: 0;
      position: relative;
    }
    p {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      margin: 0;
    }
  }
  .headerActionCommand {
    display: flex;
    .commandIcon {
      align-items: center;
      display: flex;
      &:hover,
      &.isExecuting {
        svg {
          fill: #f25844;
        }
        &:before {
          color: #f25844;
        }
      }
    }
  }

  .accordionTab {
    &:global {
      &:last-child {
        > .p-toggleable-content {
          > .p-accordion-content {
            padding-bottom: 1.5rem;
          }
        }
      }
      > .p-toggleable-content {
        > .p-accordion-content {
          padding-bottom: 0.1rem;
        }
      }
    }
  }
  .accordionContent {
    background-color: rgb(245, 247, 250);
    &:global {
      > .p-accordion-content {
        background-color: rgb(245, 247, 250);
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltipText {
  visibility: hidden;
  width: 120px; /* Adjust as needed */
  background-color: white;
  border-color: #717e90;
  color: #575656;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust as needed */
  left: 50%;
  margin-left: -60px; /* Adjust as needed */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

:global {
  .p-tree-container {
    .p-treenode-children {
      .p-treenode.command {
        .p-treenode-content {
          color: #232a34;
          font-size: 14px;
          font-family: DM Sans;
          font-weight: 400;
          line-height: 24px;
          .label {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
