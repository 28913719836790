.treeView {
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .spinner {
    margin-left: -2.2rem;
    position: absolute;
  }
  .expandIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    background: #bac1cf;
  }
  .node {
    color: #232a34;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem;
    &.nodeRoot {
      font-size: 1rem;
      .nodeRoot {
        font-size: 0.875rem;
      }
    }
    .label {
      display: flex;
      overflow: hidden;
      &:hover {
        color: #f25844;
        .icon {
          &::before {
            color: #f25844;
          }
        }
      }
    }
  }
  :global {
    .p-tree-container {
      .p-treenode-leaf {
        .p-treenode-content {
          .p-treenode-label {
            font-family: 'DM Sans';
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;
          }
        }
        &:before {
          border-top: 2px dashed transparent;
        }
      }
      .p-treenode-children {
        .p-treenode-leaf {
          position: relative;

          &:before {
            display: inline-block;
            content: '';
            position: absolute;
            top: 0.9rem;
            left: -7px;
            width: 2.2rem;
            height: 0;
            border-top: 2px dashed #bac1cf;
            z-index: 1;
          }

          &:after {
            display: inline-block;
            content: '';
            position: absolute;
            top: 0px;
            left: -7px;
            width: 2px;
            height: 100%;
            border-left: 2px dashed #bac1cf;
            z-index: 1;
          }
          &:last-child {
            &:after {
              display: inline-block;
              content: '';
              position: absolute;
              top: -1px;
              left: -7px;
              width: 2px;
              height: 50%;
              border-left: 2px dashed #bac1cf;
              z-index: 1;
            }
          }
          .p-treenode-label {
            font-family: 'DM Sans';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;
          }
        }
      }
    }
  }
}

:global {
  @layer primereact {
    .p-tree-container {
      margin: 0;
      padding: 0;
      list-style-type: none;
      overflow: auto;
    }

    .p-treenode-children {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .p-treenode-selectable {
      cursor: pointer;
      user-select: none;
    }

    .p-tree-toggler {
      cursor: pointer;
      user-select: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
    }

    .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
      visibility: hidden;
    }

    .p-treenode-content {
      display: flex;
      align-items: center;
    }

    .p-tree-filter {
      width: 100%;
    }

    .p-tree-filter-container {
      position: relative;
      display: block;
      width: 100%;
    }

    .p-tree-filter-icon {
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
    }

    .p-tree-loading {
      position: relative;
      min-height: 4rem;
    }

    .p-tree .p-tree-loading-overlay {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
