$icomoon-font-family: 'unskript-icon' !default;
$icomoon-font-path: './fonts' !default;

$unskriptIcon-analyse_problem: unquote('"\\e901"');
$unskriptIcon-copy: unquote('"\\e902"');
$unskriptIcon-details: unquote('"\\e903"');
$unskriptIcon-execute: unquote('"\\e904"');
$unskriptIcon-iteration: unquote('"\\e905"');
$unskriptIcon-mask: unquote('"\\e906"');
$unskriptIcon-plus: unquote('"\\e907"');
$unskriptIcon-possible-diagnostics: unquote('"\\e908"');
$unskriptIcon-possible-fixes: unquote('"\\e909"');
$unskriptIcon-progress_activity: unquote('"\\e90a"');
$unskriptIcon-re-run-check: unquote('"\\e90b"');
$unskriptIcon-reset: unquote('"\\e90c"');
$unskriptIcon-revert: unquote('"\\e90d"');
$unskriptIcon-send_a_message: unquote('"\\e90e"');
$unskriptIcon-sets-of-recommendations: unquote('"\\e90f"');
$unskriptIcon-AI: unquote('"\\e910"');
$unskriptIcon-calendar: unquote('"\\e911"');
$unskriptIcon-chart: unquote('"\\e912"');
$unskriptIcon-check: unquote('"\\e913"');
$unskriptIcon-checks: unquote('"\\e914"');
$unskriptIcon-chevron_left: unquote('"\\e915"');
$unskriptIcon-chevron_right: unquote('"\\e916"');
$unskriptIcon-close: unquote('"\\e917"');
$unskriptIcon-error: unquote('"\\e918"');
$unskriptIcon-expand_less: unquote('"\\e919"');
$unskriptIcon-expand_more: unquote('"\\e91a"');
$unskriptIcon-fail: unquote('"\\e91b"');
$unskriptIcon-filter: unquote('"\\e91c"');
$unskriptIcon-ignore: unquote('"\\e91d"');
$unskriptIcon-minus: unquote('"\\e91e"');
$unskriptIcon-pass: unquote('"\\e91f"');
$unskriptIcon-report: unquote('"\\e920"');
$unskriptIcon-search: unquote('"\\e921"');
$unskriptIcon-sorting: unquote('"\\e922"');
$unskriptIcon-sorting_down: unquote('"\\e923"');
$unskriptIcon-sorting_up: unquote('"\\e924"');
