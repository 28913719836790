.reqRecommendationsModal {
  display: flex;
  width: 22.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  .content {
    align-self: stretch;
    color: #717e90;
    font-family: 'DM Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 0;
  }
}

.reqRecommendationsModalFooter {
  display: flex;
  height: 3.125rem;
  padding: 0.375rem 1rem;
  align-items: center;
  gap: 0.5rem;
}
